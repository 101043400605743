<template>
  <v-dialog v-model="dialog" max-width="500" persistent>
    <v-card class="text-center" >
      <h3 class="pa-5">
        {{ message }}
      </h3>
      <v-card-actions>
        <v-row>
          <v-col cols="6">
            <v-btn color="error" text @click="cancel">
             Не
            </v-btn>
          </v-col>
          <v-col cols="6">
            <v-btn color="primary" text @click="confirm">
             Да
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
export default {
  name: "ConfirmDialog",
  data: () => ({
    dialog: false,
    message: "",
    resolve: null,
    reject: null
  }),
  methods: {
    open(message) {
      this.dialog = true
      this.message = message
      return new Promise(((resolve, reject) => {
        this.resolve = resolve
        this.reject = reject
      }))
    },
    confirm() {
      this.dialog = false
      this.resolve(true)
    },
    cancel() {
      this.dialog = false
      this.resolve(false)
    }

  }
}
</script>

<style scoped>

</style>